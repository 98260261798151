import React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '../Base/Footer'
import Header from '../Base/Header'

import GalleryList from './GalleryList'

export default class galleryPageController extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <title>Bridie Faye Art - Gallery</title>
                    <link rel="canonical" href="https://bridiefayeart.com/Gallery" />
                </Helmet>
                <Header></Header>
                <div className="page">
                    <GalleryList/>
                </div>
                <Footer></Footer>
            </React.Fragment>
        );
    } 
}