import React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '../Base/Footer'
import Header from '../Base/Header'

import AboutContent from './AboutContent'

export default class aboutPageController extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <title>Bridie Faye Art - About</title>
                    <link rel="canonical" href="https://bridiefayeart.com/About" />
                </Helmet>
                <Header></Header>
                <div className="page">
                    <AboutContent/>
                </div>
                <Footer></Footer>
            </React.Fragment>
        );
    } 
}