import React from 'react';

import galleryStyle from './Gallery.module.scss';

export default class galleryItem extends React.Component {

    render() {
        return (
            <li className={galleryStyle.galleryItem} onClick={() => this.props.openImage(this.props.index)}>
                <img src={this.props.image} alt="gallery item" />
            </li>
        );
    }
};