import React from 'react';
import { NavLink } from "react-router-dom";
import logo from '../../images/svgs/website-logo-white.svg' 

// import { ReactComponent as FbLogo } from '../../images/svgs/Facebook-logo.svg'
import { ReactComponent as InstaLogo } from '../../images/svgs/Insta-logo.svg'
import { ReactComponent as EtsyLogo } from '../../images/svgs/Etsy-logo.svg'


import footerStyle from './Footer.module.scss';

const footer = (props) => {
    return (
        <footer>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1190.64 141.3">
                <path className="cls-1" d="M1190.64,482.87q-595.32,0-1190.64,0V413.74c88.07-7.46,176.49-13.33,264.52-21.54,83.64-9.79,166.82-22.61,251-27.32,41.46-2.56,83.28-.53,124,8.13C690.25,387.7,742.18,398.12,795,402.77c72.62,6.25,142.28-19.23,213.25-30.32,59.93-13.66,121.4-10.56,182.4-10.55Z" transform="translate(0 -361.59)"/>
            </svg>
            <div className={footerStyle.backgroundColour}></div>
            <div className={footerStyle.footerContainer}>
                <div className={footerStyle.pageWidth}>
                    <div>
                        <NavLink className={footerStyle.mainLogo} activeClassName={footerStyle.isActive} to="/Gallery"><img src={logo} alt="Bridie Faye Art"/></NavLink>
                        <p><span>&#169;</span> Bridie Faye Art. All rights reserved.</p>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/bridiefayeart/" rel="noopener noreferrer" target="_blank"><InstaLogo /></a>
                        <a href="https://www.etsy.com/uk/shop/BridieFayeArt" rel="noopener noreferrer" target="_blank"><EtsyLogo /></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default footer;