import React from 'react';
import { NavLink } from "react-router-dom";

import menuStyle from './Menu.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from '../../images/svgs/website-logo.svg' 

export default class menu extends React.Component {
    constructor() {
        super();
        this.state = {
            classes: menuStyle.nav
        }
    }
    
    clickHandler() {
        let lClasses = this.state.classes
        if (this.state.classes.includes(menuStyle.active)) {
            lClasses = lClasses.replace(menuStyle.active,"")
        } else {
            lClasses = lClasses + " " + menuStyle.active
        }
        this.setState({ classes: lClasses});
    }

    render() {
        return (
            <div className={menuStyle.navWrapper}>
                <NavLink className={menuStyle.mainLogo} activeClassName={menuStyle.isActive} to="/Gallery"><img src={logo} alt="Bridie Faye Art"/></NavLink>
                <span className={menuStyle.mobileButton} onClick={this.clickHandler.bind(this)}><FontAwesomeIcon icon={['fas', 'bars']}></FontAwesomeIcon></span>
                <nav className={this.state.classes}> 
                    <NavLink activeClassName={menuStyle.isActive} to="/Gallery"><span>Gallery</span></NavLink>
                    <NavLink activeClassName={menuStyle.isActive} to="/About"><span>About</span></NavLink>
                    <a href="https://www.etsy.com/uk/shop/BridieFayeArt" rel="noopener noreferrer" target="_blank">Shop</a>
                </nav>
            </div>
        );
    }
};