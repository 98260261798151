import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import GalleryPageController from './components/GalleryPage/GalleryPageController'
import AboutPageController from './components/AboutPage/AboutPageController'
import noMatchPage from './components/Base/NoMatchPage';

import './main.scss';

library.add(faFacebookSquare, faInstagram, faBars)

class App extends Component {
  render() {
    return (
          <div className="App">
            <BrowserRouter>
              <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                          <Redirect to="/Gallery" />
                        )
                    }}
                  />
                  <Route path="/Gallery" exact component={GalleryPageController} />
                  <Route path="/About" exact component={AboutPageController} />
                  <Route component={noMatchPage} />
              </Switch>
            </BrowserRouter>
          </div>
    );
  }
}

export default App;