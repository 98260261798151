import React from 'react';
import Menu from '../Base/Menu'

const header = (props) => {
    return (
        <header>
            <Menu></Menu>
        </header>
    );
};

export default header;
