import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";

import Header from '../Base/Header'

import blob2 from '../../images/blob2.png' 

import { ReactComponent as EtsyLogo } from '../../images/svgs/Etsy-logo.svg'
import { ReactComponent as FbLogo } from '../../images/svgs/Facebook-logo.svg'
import { ReactComponent as InstaLogo } from '../../images/svgs/Insta-logo.svg'

import noMatchStyle from './NoMatch.module.scss';

const noMatchPage = (props) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <title>Bridie Faye Art - 404</title>
            </Helmet>
            <Header></Header>
            <div className={noMatchStyle.holdingPage}>
                <div className={noMatchStyle.secondaryContainer}>
                    <div className={noMatchStyle.blobtext}>
                        <img className={noMatchStyle.blob} src={blob2} alt="blob2"/>
                        <h2>404 Error</h2>
                        <p>Would you like to go <Link to="/">home</Link>?</p>
                    </div>
                    <ul className={noMatchStyle.socials}>
                        <li><a href="https://www.etsy.com/uk/shop/BridieFayeArt" rel="noopener noreferrer" target="_blank"><EtsyLogo /></a></li>
                        <li><a href="https://www.instagram.com/bridiefayeart/" rel="noopener noreferrer" target="_blank"><InstaLogo /></a></li>
                        <li><a href="https://www.facebook.com/bridiefayeart/" rel="noopener noreferrer" target="_blank"><FbLogo /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default noMatchPage;