import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import blob1 from '../../images/blob1.png' 
import blob2 from '../../images/blob2.png' 
import blob3 from '../../images/blob3.png' 
import blob4 from '../../images/blob4.png' 

import aboutStyle from './About.module.scss';

import ContactForm from '../ContactPage/ContactForm';

export default class aboutContent extends React.Component {

    state = {
        pageText: null,
        loading: true,
        url: window._env_.API_URL + "/api/collections/get/BridieFayeArtPages"
    };
    
    async componentDidMount() {
        const response = await fetch(this.state.url);
        const data = await response.json();
        this.setState({ pageText: data['entries'][0], loading: false});
    }

    render() {
        return (
            <React.Fragment>
                <div className={aboutStyle.aboutContent}>
                    {
                        !this.state.pageText ? (
                            null
                        ) : (
                            <React.Fragment>
                                <h2>{ this.state.pageText.Title }</h2>
                                {
                                    this.state.pageText.Blob1Image.path ? (
                                        <div id={aboutStyle.blob1} className={aboutStyle.hasImage}>
                                            <div className={aboutStyle.imageBlock} style={{backgroundImage: "url(" + this.state.pageText.Blob1Image.path + ")"}}></div>
                                            <div className={aboutStyle.blobText}>
                                            <img className={aboutStyle.blob} src={blob1} alt="blob1"/>
                                                { ReactHtmlParser(this.state.pageText.Blob1) }
                                            </div>
                                        </div>
                                    ) : (
                                        <div id={aboutStyle.blob1} className={aboutStyle.blobText}>
                                            <img className={aboutStyle.blob} src={blob1} alt="blob1"/>
                                            { ReactHtmlParser(this.state.pageText.Blob1) }
                                        </div>
                                    )
                                }
                                {
                                    this.state.pageText.Blob2Image.path ? (
                                        <div id={aboutStyle.blob2} className={aboutStyle.hasImage}>
                                            <div className={aboutStyle.blobText}>
                                                <img className={aboutStyle.blob} src={blob2} alt="blob2"/>
                                                { ReactHtmlParser(this.state.pageText.Blob2) }
                                            </div>
                                            <div className={aboutStyle.imageBlock} style={{backgroundImage: "url(" + this.state.pageText.Blob2Image.path + ")"}}></div>
                                        </div>
                                    ) : (
                                        <div id={aboutStyle.blob2} className={aboutStyle.blobText}>
                                            <img className={aboutStyle.blob} src={blob2} alt="blob2"/>
                                            { ReactHtmlParser(this.state.pageText.Blob2) }
                                        </div>
                                    )
                                }
                                {
                                    this.state.pageText.Blob3Image.path ? (
                                        <div id={aboutStyle.blob3} className={aboutStyle.hasImage}>
                                            <div className={aboutStyle.imageBlock} style={{backgroundImage: "url(" + this.state.pageText.Blob3Image.path + ")"}}></div>
                                            <div className={aboutStyle.blobText}>
                                                <img className={aboutStyle.blob} src={blob3} alt="blob3"/>
                                                { ReactHtmlParser(this.state.pageText.Blob3) }
                                            </div>
                                        </div>
                                    ) : (
                                        <div id={aboutStyle.blob3} className={aboutStyle.blobText}>
                                            <img className={aboutStyle.blob} src={blob3} alt="blob3"/>
                                            { ReactHtmlParser(this.state.pageText.Blob3) }
                                        </div>
                                    )
                                }
                                {
                                    this.state.pageText.Blob4Image.path ? (
                                        <div id={aboutStyle.blob4} className={aboutStyle.hasImage}>
                                            <div className={aboutStyle.imageBlock} style={{backgroundImage: "url(" + this.state.pageText.Blob4Image.path + ")"}}></div>
                                            <div className={aboutStyle.blobText}>
                                                <img className={aboutStyle.blob} src={blob4} alt="blob4"/>
                                                { ReactHtmlParser(this.state.pageText.Blob4) }
                                            </div>
                                        </div>
                                    ) : (
                                        <div id={aboutStyle.blob4} className={aboutStyle.blobText}>
                                            <img className={aboutStyle.blob} src={blob4} alt="blob4"/>
                                            { ReactHtmlParser(this.state.pageText.Blob4) }
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </div>
                <ContactForm/>
            </React.Fragment>
        );
    } 
}