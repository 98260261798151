import React from 'react';

import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

import galleryStyle from './Gallery.module.scss';

import GalleryItem from './GalleryItem';

export default class GalleryList extends React.Component {
    constructor() {
        super()
        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: null,
            loading: true,
            url: window._env_.API_URL + "/api/collections/get/ArtGallery",
            isMobile: false
        }
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 767});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    
    async componentDidMount() {
        const response = await fetch(this.state.url);
        const data = await response.json();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.setState({ images: data['entries'], loading: false});
    }

    openImage(index) {
        this.setState({ 
            isOpen: true, 
            photoIndex: index
        });
    }

    render() {
        this.openImage = this.openImage.bind(this)
        var { photoIndex, isOpen } = this.state;
        return (
            <React.Fragment>
                    {
                        !this.state.images ? (
                            null
                        ) : (
                            <div className={galleryStyle.galleryCoumnList}>
                                <ul className={galleryStyle.column1}>
                                    {this.state.images.map((item, index) =>  {
                                        return index === 0 | index % 4 === 0 ?
                                            <GalleryItem key={index} image={ window._env_.API_URL + "/" + item.Thumbnail.path} openImage={this.openImage} index={index}></GalleryItem>
                                            :
                                            null
                                    })}
                                </ul>
                                <ul className={galleryStyle.column2}>
                                    {this.state.images.map((item, index) =>  {
                                        return index === 1 | index % 4 === 1 ?
                                            <GalleryItem key={index} image={window._env_.API_URL + "/" + item.Thumbnail.path} openImage={this.openImage} index={index}></GalleryItem>
                                            :
                                            null
                                    })}
                                </ul>
                                <ul className={galleryStyle.column3}>
                                    {this.state.images.map((item, index) =>  {
                                        return index === 2 | index % 4 === 2 ?
                                            <GalleryItem key={index} image={window._env_.API_URL + "/" + item.Thumbnail.path} openImage={this.openImage} index={index}></GalleryItem>
                                            :
                                            null
                                    })}
                                </ul>
                                <ul className={galleryStyle.column4}>
                                    {this.state.images.map((item, index) =>  {
                                        return index === 3 | index % 4 === 3 ?
                                            <GalleryItem key={index} image={window._env_.API_URL + "/" + item.Thumbnail.path} openImage={this.openImage} index={index}></GalleryItem>
                                            :
                                            null
                                    })}
                                </ul>
                            </div>
                        )
                    }

                {isOpen && this.state.loading === false && this.state.isMobile === false ? (
                    <Lightbox
                        mainSrc={window._env_.API_URL + "/" + this.state.images[photoIndex].Picture.path}
                        nextSrc={window._env_.API_URL + "/" + this.state.images[(photoIndex + 1) % this.state.images.length]}
                        prevSrc={window._env_.API_URL + "/" + this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        toolbarButtons={null}
                        enableZoom={false}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                ) : (
                    null
                )}
            </React.Fragment>
        );
    } 
}